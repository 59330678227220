@import "src/app/variables.scss";

$breadcrumb-line-height: $pt-icon-size-large - 1px !default;

.Footer {
  width : 100%;
  background: $aleph-content-background;
  box-shadow: $pt-elevation-shadow-1;
  bottom: 0;
  font-size: $pt-font-size-large;
  padding: ($aleph-grid-size / 2) $aleph-content-padding ($aleph-grid-size / 2) $aleph-grid-size;

  a {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }

  .info {
    float: right;
    padding: 0;

    .bp3-icon {
      margin-top: 1px;
    }
  }
}
