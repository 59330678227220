@import "src/app/variables.scss";

$dashboard-padding: $aleph-grid-size * 2.5;

.Dashboard {
    display: flex;
    flex-flow: row nowrap;
    align-content: stretch;
    align-items: stretch;
    padding: $dashboard-padding;

    &__menu {
        flex: flex;
        flex-direction: column;
        flex-grow: 0;
        width: 20%;
        border-right: 1px solid rgba(16, 22, 26, 0.15);
        margin-right: $dashboard-padding;
        padding-right: $dashboard-padding;
        min-width: 220px;

        @media screen and (max-width: $aleph-screen-sm-max-width) {
          display: none;
        }

        .bp3-menu {
          background-color: inherit !important;
          padding: 0;
        }

        .bp3-menu-item {
          padding: 6px 7px;

          .bp3-icon {
            margin-right: 12px;
          }

          .Count {
            float: right;
          }

          &.bp3-active .Count {
            color: white;
            background: none;
          }
        }

        .bp3-menu-header {
          border: none;
          margin-left: 0;
          margin-bottom: $aleph-grid-size;

          .bp3-heading {
            color: $blue1;
          }
        }

        .bp3-menu-divider {
          margin: $aleph-grid-size * 1.5 0;
        }

        .bp3-heading {
          // text-transform: uppercase;
          // color: $gray1;
          // font-size: 12px;
        }
    }

    &__actions {
      float: right;
    }

    &__title-container {
      margin: $dashboard-padding 0;
    }

    &__title {
      font-size: 22px;
      color: $dark-gray1;
      margin: 0 0 $aleph-grid-size;
    }

    &__subheading {
      color: $dark-gray5;
      line-height: 1.5;
      clear: both;
      max-width: 700px;
    }

    .dashboard-body {
        display: flex;
        flex-direction: column;
        flex-grow: 6;
        overflow-x: auto;
        padding-left: ($aleph-grid-size / 2);
        padding-right: ($aleph-grid-size / 2);
    }
}
