@import "src/app/variables.scss";

.EntityLabel {
  vertical-align: middle;

  /* @TODO Refactor Entity to use .bp3-text-muted instead of .untitled class */
  /* Using opacity change (instead of fixed color) is an interim measure */
  &.untitled {
    text-transform: uppercase;
    opacity: 0.5;
  }

  &.disabled {
    color: $aleph-greyed-text;
  }
}

.EntityLink {
  &:visited {
    color: $aleph-visited-link-color;
  }
}
