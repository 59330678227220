@import "src/app/variables.scss";

.CheckboxList {
  list-style: none;
  padding-left: 0;
  padding-bottom: $aleph-grid-size;
  margin: 0;

  & > li {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: ($pt-icon-size-standard + $aleph-grid-size * 1.5);
    padding-right: ($aleph-grid-size / 2);

    &.faint {
      color: $aleph-greyed-text;
    }

    & > .Tick {
      position: absolute;
      left: ($aleph-grid-size / 2);
      line-height: $aleph-line-height;



      &::before {
        content: $pt-icon-circle;

        .multi-select & {
          content: $pt-icon-square;
        }
      }

      opacity: 0.1;
      transition: opacity 0.2s ease-out, color .2s ease-out;
    }

    &.active {
      .Tick {
        opacity: 1;
        color: $pt-intent-primary;

        &::before {
          content: $pt-icon-symbol-circle;

          .multi-select & {
            content: $pt-icon-symbol-square;
          }
        }
      }

      .label {
        font-weight: bold;
      }
    }

    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > span {
      margin: ($aleph-grid-size / 2);
    }

    .count {
      color: $gray1;
      font-variant-numeric: tabular-nums;
    }
  }
}
