@import "src/app/variables.scss";

.MappingPropertyAssign {
  &.TableViewer {
    height: auto;

    .bp3-table-column-name {
      text-align: center;
      font-weight: 500;
      border-bottom: 1px solid $aleph-border-color;
    }

    .bp3-table-header-content {
      padding: 0 3px 5px;
      text-align: center;
      min-height: 80px;
    }

    .bp3-button-text {
      overflow: hidden;
      white-space: nowrap;
      max-width: 100px;
      text-overflow: ellipsis;
    }
  }

  .bp3-submenu {
    max-height: 300px;
    overflow-y: auto;
  }

  &__headerSelect {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    &__label {
      white-space: nowrap;
      margin-bottom: 3px;
    }
  }

  &__error {
    line-height: 1.4;
    font-style: italic;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
