@import "src/app/variables.scss";

.SearchFacets {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
