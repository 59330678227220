@import "src/app/variables.scss";

.DocumentViewMode {
  flex: 23;

  .outer {
    flex: 2;
    padding: ($aleph-grid-size / 2);

    .inner {
      background: white;
      box-shadow: $pt-elevation-shadow-2;
      max-width: $aleph-screen-md-max-width;
      margin-left: 0;
      margin-right: auto;
    }
  }
}