@import 'src/app/variables.scss';

.Statistics {
  display: inline-block;

  &.bp3-callout.dark {
    background-color: white;
  }

  &__headline {
    color: white !important;
    text-align: left;
    margin-bottom: 15px;

    .dark & {
      color: $blue1 !important;
    }
  }

  &__filter {
    margin-bottom: 15px;
  }

  &__list {
    list-style: none;
    padding: unset;
    margin: 0;
  }


  &__list-item {
    display: flex;
    justify-content: space-between;

    a, .bp3-button {
      color: $aleph-content-background !important;
      width: 100%;
      text-align: left;
      padding: 3px;
      transition: background-color .1s ease-out;

      &:hover {
        text-decoration: none;
        background-color: #ffffff38 !important;

        .dark & {
          color: $blue1 !important;
        }
      }

      .dark & {
        color: black !important;
      }
    }

    .bp3-button {
      box-shadow: none !important;
      background: none !important;
      border-radius: 0;
      min-height: 25px;
      justify-content: left;
      margin: 10px 0;
    }

    .bp3-button-text {
      font-weight: 600;
    }

    .inner-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .label {
      flex: 1 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 5px;
    }

    .value {
      flex: 0 0 auto;

      .dark & {
        color: $aleph-greyed-text;
      }
    }
  }
}
