@import "src/app/variables.scss";

.SearchScreen {
  .total-count-span {
    display: inline-flex;
    padding-left: 0.5em;
    font-size: 1.1em;
    font-weight: bold;
  }

  .total-count {
    display: flex;
    padding: 0 $aleph-grid-size;
    -ms-flex-align: center;
    align-items: center;
    height: 3em !important;
  }

  .total-icon {
    margin-right: 0.5em;
  }

  .facets {
    display: none;
    cursor: pointer;
    margin-top: 0.5em;
  }

  .SignInCallout {
    margin-bottom: $aleph-grid-size;
  }
}

@media screen and (max-width: $aleph-screen-sm-max-width) {
  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .SidePane {
    padding-bottom: 0 !important;
  }
}
