@import "src/app/variables.scss";

.CollectionStatisticsMode {
  margin: 0 -$aleph-content-padding/2;
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
}
