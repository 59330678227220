
/* Taming Blueprint icons and tags/badges when displayed inside tabs */
.bp3-tabs .bp3-tab .bp3-tag {
  padding: 0px 10px;
  margin-left: 5px;
  line-height: 1em;
  // font-size: 0.6em;
}

.bp3-tabs .bp3-large .bp3-tab .bp3-icon-standard {
  min-width: 20px;
  margin-right: 10px;
}

.bp3-tabs .bp3-large .bp3-tab .bp3-tag {
  padding: 0 10px;
  margin-left: 10px;
}

/* Buttons and Inputs in control groups have margin values they shouldn't
* for some reason, even though my inspector says '0em'. */
.bp3-control-group * {
  margin-top: 0;
  margin-left: 0;
}

.bp3-tab-list {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
}
