@import "src/app/variables.scss";

.Notification {
    padding: $aleph-grid-size;
    line-height: 1.5em;
    border-bottom: 1px solid $aleph-border-color;

    .timestamp {
        float: right;
        color: $aleph-greyed-text;
        // font-size: $pt-font-size-small;
    }

    .param {
        font-weight: bold;
    }

    .EntityLabel {
      vertical-align: inherit;
    }
}
