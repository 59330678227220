@import "src/app/variables.scss";

.SearchFacet {
  margin-bottom: $aleph-grid-size;

  & > .opener {
    display: flex;
    padding: 0 $aleph-grid-size;
    align-items: center;
    cursor: pointer;
    background: $aleph-facet-background !important;

    &.active {
      font-weight: bold;
    }

    &:not(.clickable) {
      pointer-events: none;
    }

    & > .caret {
      margin-right: $aleph-grid-size * 0.5;
      transition: transform 100ms;

      &.rotate {
        transform: rotate(90deg);
      }
    }

    .FacetName {
      padding: $aleph-grid-size 0;
      font-size: 1.1em;
      font-weight: bold;

      .bp3-icon {
        margin-right: 10px !important;
      }
    }

    .FilterCount {
      padding-left: $aleph-grid-size;
      font-weight: normal;
    }

    & > .ClearButton {
      position: absolute;
      top: $aleph-grid-size/2;
      right: $aleph-grid-size/2;

      &a:hover {
        // Element is on a background (set by .clickable), so invert its own background.
        background: $aleph-content-background;
      }
    }

    .bp3-tag {
      position: absolute;
      right: $aleph-grid-size;
      padding-top: 0;
      padding-bottom: 0;
      font-weight: bold;
      background-color: $aleph-theme-intent;
      color: white;
    }
  }

  .CheckboxList {
    padding-top: $aleph-grid-size;
  }

  p {
    text-align: center;
  }

  .spinner {
    margin: $aleph-grid-size auto $aleph-grid-size auto !important;
  }

  .ShowMore {
    margin-top: $aleph-grid-size;
    font-weight: bold;
  }

  .updating {
    opacity: 0.5;
  }
}
