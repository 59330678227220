.AuthenticationScreen {
  width: 28em !important;

  .inner {
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
  }

  .menu-divider {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .signin-button {
    float: right;
  }

  .hide {
    display: none;
  }

  .link-box {
    margin-top: 2em;
    margin-bottom: 2em;
    // margin-left: 8.5em;
  }
}