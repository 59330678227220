@import "src/app/variables.scss";

.EntityConnections {
  .bp3-text-muted {
    margin-top: $aleph-grid-size * 0.5 !important;
  }
}

.EntityTagsMode {
  margin-bottom: 20px;
  overflow-x: auto;
  padding: 40px;
}