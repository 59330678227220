@import "src/app/variables.scss";

.CollectionAccessDialog {
  .bp3-control.bp3-checkbox {
    margin-bottom: 0;
  }

  .header-topic {
    font-weight: bold;
  }
}
