@import "src/app/variables.scss";

.MappingVerify {
  &__item {
    border: 1px solid;
    box-shadow: none !important;
    margin: $aleph-grid-size 0;
  }

  &__section {
    margin: 15px 0;
    &__title {
      font-weight: 500;
    }

    &__value {
      margin-top: 10px;
    }
  }

  &__list.bp3-html-table {
    width: 100%;
    margin-top: $aleph-grid-size;
    border-collapse: collapse !important;
    border-spacing: 0 !important;

    td {
      box-shadow: none !important;
      vertical-align: middle !important;
      padding: 3px 10px !important;
      border: 1px solid $aleph-border-color;

      &.MappingVerify__listItem__close {
        width: 10px !important;
        padding: 0 !important;
      }
    }
  }
}
