@import "src/app/variables.scss";

@mixin large {
  @media screen and (min-width: $aleph-screen-md-max-width) {
    @content;
  }
}

.ItemOverview {
  &.horizontal {
    margin-bottom: $aleph-content-padding;

    @include large {
      display: flex;
      padding: $aleph-content-padding;
    }
  }

  &__heading {
    border-bottom: 1px solid $aleph-border-color;
    padding: ($aleph-grid-size * 4) 0;

    .preview & {
      background-color: $aleph-breadcrumbs-background;
      padding: $aleph-grid-size*4 $aleph-grid-size*2;
      border: none;
    }

    .horizontal & {
      @include large {
        flex: 1 1 35%;
        padding: $aleph-grid-size*4;
        padding-left: 0;
        border-bottom: none;
        max-width: 800px;
        border-right: 1px solid $aleph-border-color;
      }

      @media screen and (max-width: $aleph-screen-lg-max-width) {
        flex-basis: 50%;
      }
    }

    &__title, &__subtitle, &__description {
      margin: 10px 0;
      overflow-wrap: break-word;
    }

    &__title {
      line-height: 1.5;
    }
  }

  &__content {
    padding: ($aleph-grid-size * 4) 0;

    .preview & {
      padding: 0;
    }

    .horizontal & {
      @include large {
        flex: 1 1 65%;
        padding-left: ($aleph-grid-size * 4);
      }

      @media screen and (max-width: $aleph-screen-lg-max-width) {
        flex-basis: 50%;
      }
    }
  }
}
