@import "src/app/variables.scss";

.TextViewer {
  overflow-x: hidden;
  padding: $aleph-grid-size;
  box-shadow: $pt-elevation-shadow-1;
  margin-bottom: 2 * $aleph-grid-size;

  pre {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: $pt-font-family;
    line-height: 1.4;
    font-size: 1.1 * $aleph-font-size;
    box-shadow: unset;
  }
}