@import "src/app/variables.scss";

.FolderViewer {
  .folder-filter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-bottom: $aleph-grid-size;
  }

  .bp3-input-group {
    max-width: 15em;
  }
  
  .folder-empty {
    padding: $aleph-grid-size*2 $aleph-grid-size;
    text-align: center;
  }

  .warning-folder {
    width: 100%;
    height: 2.5em;
    border-color: $warning-border-color;
    background-color: $warning-background-color;
    color: $warning-text-color;
    display: inline-flex;
    padding-left: 35%;
    padding-top: 6px;
  }
}
