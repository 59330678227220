@import "src/app/variables.scss";

.DocumentManager {
  &__content {
    margin-top: $aleph-grid-size*2;

    &__empty {
      padding: 0 $aleph-grid-size*2 $aleph-grid-size*2;
      border: 2px dashed rgba(92, 112, 128, 0.6);
      cursor: pointer;

      .bp3-heading {
        color: rgba(92, 112, 128, 0.6);
        line-height: 1.5;
      }
    }
  }
}
