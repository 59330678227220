@import "src/app/variables.scss";

.Navbar {
  &.bp3-navbar {
    display: flex;
    padding: 0 $aleph-content-padding;
    justify-content: space-between;
    background-color: $dark-gray1 !important;

    @media screen and (max-width: $aleph-screen-sm-max-width) {
      padding: 0 $aleph-mobile-content-padding;
    }

    .back-button {
      display: none;
    }

    a {
      color: inherit;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }

    form {
      .bp3-control-group {
        width: 100%;
      }

      input {
        background: #fff;
        color: $pt-text-color;
      }
    }

    .icon {
      display: none;
      @media screen and (max-width: $aleph-screen-sm-max-width) {
        float: right;
        margin-top: 1em;
      }
    }
  }

  .bp3-navbar-heading {
    vertical-align: middle;
    font-weight: bold;
    margin: 0 $aleph-grid-size;
  }

  &__left-group {
    padding-right: $aleph-grid-size;

    @media screen and (max-width: $aleph-screen-sm-max-width) {
      &.hide {
        display: none;
      }
    }
  }

  &__middle-group {
    flex: 4 1 auto;
    @media screen and (max-width: $aleph-screen-sm-max-width) {
      display: none !important;
    }
    &.mobile-force-open {
      display: block !important;
    }
  }

  &__home-link {
    height: 28px;
    img {
      height: 28px;
    }
  }

  &__search-container {
    flex: 4 1 auto;
    padding: 10px 5px;
    max-width: 600px;
  }

  &__collections-button {
    @media screen and (max-width: $aleph-screen-sm-max-width) {
      display: none !important;
    }
  }

  &__mobile-back-button, &__mobile-search-toggle {
    @media screen and (min-width: $aleph-screen-sm-max-width) {
      display: none;
    }
  }

  .SearchBox__search-tips {
    @media screen and (max-width: $aleph-screen-md-max-width) {
      display: none;
    }
  }

  .bp3-button, .bp3-menu {
    border-radius: 0;
  }


  .bp3-button-text {
    @media screen and (max-width: $aleph-screen-md-max-width) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 10vw;
    }
    @media screen and (max-width: $aleph-screen-sm-max-width) {
      max-width: 20vw;
    }
  }

  .bp3-divider {
    margin: 0 5px !important;
  }

  .mobile-hidden {
    @media screen and (max-width: $aleph-screen-sm-max-width) {
      display: none;
    }
  }
}
