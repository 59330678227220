@import "src/app/variables.scss";

$preview-padding: $aleph-grid-size*1.5;

.EntityPreview {
  &__content {
    padding: $preview-padding;
    overflow-y: scroll;
  }

  &__overlay-container .bp3-overlay.bp3-overlay-container {
    right: 0;
    left: auto;
    overflow: visible;
  }

  .info-tabs-padding {
    padding: $preview-padding 0;
  }

  .bp3-drawer-header {
    padding: 0 $aleph-grid-size/2;
    box-shadow: 0 1px 2px rgba(16, 22, 26, 0.15);

    .bp3-heading {
      margin: 0 !important;
    }
  }
}
