@import 'src/app/variables.scss';

$vertical-spacing: $aleph-grid-size*5;

.HomeScreen {
  &__section {
    text-align: center;

    &.title-section {
      min-height: 100vh;
      background-color: black;
      background-image: url("../../assets/europe_asia_dark.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      color: white;
    }

    &__content {
      max-width: 800px;
      margin: auto;
      padding: 70px 30px;
      vertical-align: middle;
    }
  }

  &__text-container {
    margin: 0 0 $vertical-spacing;
  }

  &__title {
    font-size: 42px;
    margin: 0;
  }

  &__description {
    font-size: 16px;
    margin: $vertical-spacing/2 0;
  }
}
