@import "src/app/variables.scss";

.AuthButtons {
  display: inline-flex;
  @media screen and (max-width: $aleph-screen-md-max-width) {
    margin-left: 0;
  }

  .menu-item-width {
    min-width: 0 !important;
  }

  &__popover {
    border-radius: 0 !important;
    background-color: #3a454d !important;
  }
}
