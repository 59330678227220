@import "src/app/variables.scss";

.CollectionStatus {
    background-color: $light-gray4 !important;
    color: black;

    .total-count {
      font-size: 12px;
      margin: 0 !important;
    }

    .progress-area {
        flex-grow: 1;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .bp3-progress-bar {
            flex-grow: 10;
            margin-right: $aleph-grid-size;
        }
    }
}
