@import 'src/app/variables.scss';

.StatisticsGroup {
  color: $aleph-content-background;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  align-content: stretch;
  align-items: stretch;
}
