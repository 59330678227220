@import "src/app/variables.scss";

.AlertsManager {
  .bp3-callout {
    max-width: 700px;
  }

  .add-form {
    padding-top: $aleph-grid-size;
    padding-bottom: $aleph-grid-size*2;
    width: 100%;
    max-width: 700px;
  }
}
