@import "src/app/variables.scss";

.MappingKeyAssign {
  &__item {
    position: relative;
    color: white;
    box-shadow: none !important;
    margin: $aleph-grid-size 0;

    &__close {
      position: absolute;
      top: $aleph-grid-size/2;
      right: $aleph-grid-size/2;

      svg {
        fill: white !important;
      }
    }

    &__title.bp3-heading {
      color: white;
    }

    &__property {
      display: table;
      width: 100%;

      &__label, &__value {
        display: table-cell;
      }

      &__value {
        width: 75%;
        padding-left: 15px;
      }

      &__label {
        width: 25%;
        margin: 10px 0;
      }
    }

    .bp3-form-helper-text {
      color: white !important;
    }
  }

  &__properties {
    list-style: none;
  }
}
