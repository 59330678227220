@import "src/app/variables.scss";


.CasesIndexScreen {
  .explainer {
    background-color: black;
    background-image: url("../../assets/europe_asia_dark.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    flex-grow:0;

    .ContentPane, .SidePane {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    &, h1 {
      color: white;
    }

    .bp3-icon {
      float: right;
    }
    
    p.description-explanation {
      margin-bottom: $aleph-grid-size * 2;
      line-height: 1.5em;
      font-size: 1.1em;
    }
  }

  ul.results {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}
