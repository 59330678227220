@import "src/app/variables.scss";

.Count {
  &.bp3-tag {
    font-weight: 600;
  }
  &.no-fill {
    background: none !important;
  }

  &.bp3-intent-primary, .bp3-tab[aria-selected="true"] > & {
    background-color: $pt-intent-primary !important;
    color: white !important;
  }
}
