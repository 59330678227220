@import "src/app/variables.scss";

.Breadcrumbs {
  z-index: 1;
  padding: $aleph-grid-size/2 $aleph-content-padding;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background-color: $aleph-breadcrumbs-background;

  @media screen and (max-width: $aleph-screen-sm-max-width) {
    padding-left: $aleph-mobile-content-padding;
    padding-right: $aleph-mobile-content-padding;
  }

  &__main, &__right {
    margin: 2px 0;
  }

  .bp3-breadcrumbs {
    height: auto;

    & > li {
      margin: 5px 0;
      &::after {
        margin: 0 10px;
      }
    }
  }

  .bp3-breadcrumb {
    font-size: $aleph-font-size;

    .bp3-icon {
      margin-right: $aleph-icon-padding;
    }
  }

  .bp3-breadcrumb-current {
    pointer-events: none;
    color: $blue1;

    .bp3-spinner-head {
      stroke: $blue1;
    }
  }
}
